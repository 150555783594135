module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://api.velarca.hr/graphql","schema":{"perPage":5,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000,"requestConcurrency":15,"previewRequestConcurrency":5},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Veleučilište Arca","short_name":"Arca","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/logo_red.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a08d733b0b0560bbfe06952e4cc0688d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["hr","en"],"defaultLanguage":"hr","siteUrl":"http://localhost:8000","i18nextOptions":{"debug":false,"fallbackLng":"hr","cleanCode":true,"supportedLngs":["hr","en"],"defaultNS":"translation","nonExplicitSupportedLngs":false,"interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
