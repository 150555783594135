// In your gatsby-browser.js or gatsby-ssr.js
import React from 'react';
import { IntlProvider } from 'react-intl';
import { getCurrentLangKey } from 'ptz-i18n';

export const wrapPageElement = ({ element, props }) => {
  const { langKey } = getCurrentLangKey({ defaultLangKey: 'hr' });
  
  return (
    <IntlProvider locale={langKey}>
      {element}
    </IntlProvider>
  );
};
